import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 736.000000 552.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,552.000000) scale(0.100000,-0.100000)">


<path d="M0 2760 l0 -2760 3680 0 3680 0 0 2760 0 2760 -3680 0 -3680 0 0
-2760z m3530 1135 c0 -30 -4 -36 -27 -41 -181 -41 -228 -87 -302 -299 -35 -98
-121 -398 -121 -419 0 -11 21 -6 68 17 88 44 111 58 326 207 113 78 462 383
471 411 11 35 -18 49 -101 49 -100 0 -104 2 -92 51 l11 39 66 -7 c86 -10 430
-10 499 0 47 7 53 6 50 -9 -2 -11 -34 -27 -94 -48 -80 -28 -195 -94 -252 -145
-67 -61 -294 -252 -332 -281 -26 -19 -85 -65 -131 -101 -105 -82 -245 -179
-258 -179 -18 0 -13 -12 30 -75 82 -120 114 -196 239 -575 136 -409 173 -497
265 -625 52 -73 149 -151 203 -162 72 -15 303 -9 355 10 158 57 251 147 321
312 27 64 30 80 30 190 1 121 1 121 -34 179 -38 63 -98 109 -164 127 -43 12
-282 29 -303 22 -6 -3 -14 -20 -18 -39 -13 -70 -117 -461 -142 -536 l-26 -77
-28 19 c-28 21 -86 101 -114 161 -20 42 -24 21 68 379 36 140 93 363 127 495
34 132 72 282 86 334 13 51 24 99 24 105 0 17 -102 48 -177 54 -56 4 -63 7
-63 26 0 45 11 49 111 42 52 -3 202 -10 334 -15 318 -13 401 -37 517 -152 72
-72 93 -126 93 -234 -1 -85 -3 -95 -43 -177 -52 -108 -119 -172 -244 -231 -48
-23 -87 -45 -88 -48 0 -4 19 -10 43 -13 62 -9 159 -58 210 -105 24 -22 56 -66
73 -98 25 -50 29 -72 32 -155 8 -227 -112 -426 -339 -557 -154 -89 -284 -121
-513 -124 -149 -2 -165 0 -220 22 -258 104 -396 294 -561 771 -142 413 -180
495 -304 659 l-25 34 -17 -64 c-9 -35 -42 -161 -74 -279 -84 -319 -123 -474
-130 -525 -7 -42 -6 -45 21 -55 52 -20 159 -39 188 -33 28 6 28 5 21 -28 -4
-19 -10 -37 -14 -42 -4 -4 -84 -1 -177 8 -148 13 -186 13 -304 0 -74 -8 -159
-19 -189 -25 -77 -15 -78 -15 -73 25 3 28 9 35 26 35 32 0 125 38 167 68 71
51 112 142 196 442 109 388 283 1053 298 1141 l7 35 -54 18 c-29 10 -83 20
-120 23 -70 6 -73 9 -61 52 6 24 7 24 107 17 322 -20 304 -21 639 33 4 0 7
-15 7 -34z"/>
<path d="M4368 3019 l-96 -382 92 6 c155 10 256 56 347 155 86 94 126 207 117
327 -6 72 -32 127 -85 176 -49 48 -89 67 -171 85 -118 24 -96 64 -204 -367z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
